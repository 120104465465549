import React, { Component } from 'react';
import {Snackbar,SnackbarAction} from '@rmwc/snackbar';

class Notification extends Component {

    constructor(props){
      super(props)
    }

    render() {
        return (
          <div>
            <Snackbar
              open={this.props.open}
              onClose={this.props.clearNotification}
              message={this.props.msg}
              icon={this.props.icon}
              timeout={this.props.timeout}
              action={
                <SnackbarAction
                  label="Close"
                  onClick={() => console.log('')}
                />
              }
            />
          </div>
        );
      }
}

export default Notification;