import React from 'react';
import Loadable from 'react-loadable'

import Dashboard from './views/Pages/Dashboard';

function Loading() {
  return <div><i className="text-white"> </i> Loading...</div>;
}



const Menu = Loadable({
  loader: () => import('./views/Scaffolds/Menu'),
  loading: Loading,
});

const CustomerLogin = Loadable({
  loader: () => import('./views/Scaffolds/customerLogin'),
  loading: Loading,
});

const CustomerReg = Loadable({
  loader: () => import('./views/Scaffolds/customerRegister'),
  loading: Loading,
});

const Instagram = Loadable({
  loader: () => import('./views/Scaffolds/Instagram'),
  loading: Loading,
});

const FastTrack = Loadable({
  loader: () => import('./views/Scaffolds/Loyalty'),
  loading: Loading,
});

const Store = Loadable({
  loader: () => import('./views/Scaffolds/store'),
  loading: Loading,
});

const Topups = Loadable({
  loader: () => import('./views/Scaffolds/Topups'),
  loading: Loading,
});

const ClaimRwd = Loadable({
  loader: () => import('./views/Scaffolds/claimCoupons'),
  loading: Loading,
});

const Summary = Loadable({
  loader: () => import('./views/Scaffolds/summary'),
  loading: Loading,
});

const FastRwd = Loadable({
  loader: () => import('./views/Scaffolds/fastrewards'),
  loading: Loading,
});

const Tnx = Loadable({
  loader: () => import('./views/Scaffolds/transactions'),
  loading: Loading,
});

const Redemptions = Loadable({
  loader: () => import('./views/Scaffolds/redemptions'),
  loading: Loading,
});


const Qr = Loadable({
  loader: () => import('./views/Scaffolds/Qr'),
  loading: Loading,
});

const Fasttrack = Loadable({
  loader: () => import('./views/Scaffolds/Fasttrack'),
  loading: Loading,
});

const Queue = Loadable({
  loader: () => import('./views/Scaffolds/queue'),
  loading: Loading,
});



// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/app', exact: true, name: 'Home', component: Menu} ,
  { path: '/app/menu', exact: true, name: 'Home', component: Menu} ,
  { path: '/app/customer-login', exact: true, name: 'Customer Login', component: CustomerLogin},
  { path: '/app/customer-register', exact: true, name: 'Customer Registration', component: CustomerReg},
  //{ path: '/app/instagram', exact: true, name: 'Instagram Feed', component: Instagram},
  //{ path: '/app/claimrewards', exact: true, name: 'Claim Rewards', component: ClaimRwd},
  { path: '/app/redemptions', exact: true, name: 'QR Redemptions', component: Redemptions},
  { path: '/app/transactions', exact: true, name: 'Transactions', component: Tnx},  
  { path: '/app/qr', exact: true, name: 'Qr Page', component: Qr},
  { path: '/app/fasttrack', exact: true, name: 'Fast Track', component: Fasttrack},
  /*{ path: '/confirm-otp', exact: true, name: 'C Registration', component: ConfirmOtp},*/
  { path: '/app/loyalty', exact: true, name: 'Loyalty', component: FastTrack},
  { path: '/app/store', exact: true, name: 'Store', component: Store},
  { path: '/app/topups', exact: true, name: 'Topups', component: Topups},
  { path: '/app/queue', exact: true, name: 'Queue', component: Queue},
  //{ path: '/app/fastrewards', exact: true, name: 'Fast Reward', component: FastRwd},
  
];

export default routes;
