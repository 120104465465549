import React, { Component } from 'react';
import {APIHOST,getToken,isLoggedIn,getMerchantDetail,setOutlet,updateoutletStatus} from '../../../gaia';
import Axios from 'axios';
import Notification from '../../Components/Notification'
import ToolBar from '../../Components/toolbarNL'
import { Button, Form, FormGroup, Label, Input, FormText,Spinner } from 'reactstrap';

import Logo from '../../../assets/img/logo.png'

class Outlet extends Component {

  constructor(props){
    super(props)

    this.state = {
      outlets: [],
      outletId: "",
      sb: {msg : '', open : false, icon:''},
      loaded: false,
      loading: true,
      error: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkLogin = this.checkLogin.bind(this);
    this.loadData = this.loadData.bind(this);
    if(!isLoggedIn()){
      this.props.history.push(`/login`)
    return;
    }
  }

  handleChange (event) {
    this.setState( {[event.target.name]: event.target.value} )
  }

  checkLogin(){
    if(!isLoggedIn()){ 
      this.props.history.push(`/login`);
    }
  }

  loadData(){
    var _this = this;
    _this.setState({loading: true});
    _this.setState({error: false}); 
    Axios.post(`${APIHOST}outlets/list/${getMerchantDetail("merchant_id")}`, {
      token: getToken(),
    }).then(function(res){
      
      let {data} = res;
      _this.setState({loading: false});
      if(typeof(res.error) != "undefined"){
        _this.setState({error: true});
      }else{
        //insert outlets here
        _this.setState({outlets: data.outlets});  
        _this.setState({loaded: true});  
      }
    }).catch(function(er){
        
      _this.setState({loading: false});
      if(typeof(er.response) !== "undefined"){
        _this.setState({sb : {msg : er.response.statusText, open : true, icon : 'close'}})
      }else{
        _this.setState({sb : {msg : er, open : true, icon : 'close'}})
      }
      _this.setState({error: true});
    })
  }


  handleSubmit = (evt) =>{
    var that = this;
    evt.preventDefault();
    for(var i = 0; i < this.state.outlets.length; i++){
      if(this.state.outlets[i].id == this.state.outletId){
        setOutlet(this.state.outlets[i]);
      }
    }
    updateoutletStatus(true);
    that.props.history.push("/app");

  }

  componentWillMount(){
    this.loadData();
  }

  render() {
    const {loading,loaded,error,outlets,sb} = this.state;
    return (
      <div className="container bg">
        <ToolBar title="" past="/" hasMenu={true} history={this.props.history} />
        <div id="login">
          <Notification open={sb.open} timeout={3000} clearNotification={()=>{this.clearNotification()}}  msg={sb.msg} icon={sb.icon} />
          <div className="inner">
            <div className="top">
              <div className="frm-box">
                <img src={Logo} className="central-logo" />
                <div className={loading ? "centered text-white show" :  "centered text-white hide"}>
                  <h5>Retrieving your outlets....</h5>
                  <Spinner color="primary" size="lg"/>
                </div>
                <div className={error ? "centered text-white show" :  "centered text-white hide"}>
                  <h5>An error occured while loading your outlets, Click the button below to retry.</h5>
                  <Button className="btn-secondary" onClick={this.loadData}>Retry</Button>
                </div>
                <form autoComplete="off" className={loaded ? "show" :  "hide"} onSubmit={this.handleSubmit}>

                  <h5 className="text-white centered mb-30">Welcome Back, {getMerchantDetail()}</h5> 
                  <FormGroup>
                  <Label for="username">Select an Outlet</Label>
                  <Input type="select"  onChange={event => this.handleChange(event)} name="outletId" id="outletId" className="select" required>
                    <option value="">-- Select One --</option>
                      {outlets.map(outlet => (
                        <option key={outlet.id} value={outlet.id}>{outlet.title}</option>
                      ))
                    }
                  </Input>
                  </FormGroup>
                  <Button type="submit" className="tb-big-btn btn-secondary">DONE</Button>
                </form>
              </div>
            </div>
            <div className="btm">
           
            </div>
          </div>
        </div>
      
      </div>



    );
  }
}

export default Outlet;
