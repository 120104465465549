import React, { Component } from 'react';
import {APIHOST,getToken,destroySession,getMerchantDetail} from '../../gaia';
import Axios from 'axios';
import {ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class toolbarNL extends Component {

    constructor(props){
      super(props)
      this.state = {ddOpen : false}
      this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout(){
      var _this = this
      Axios.post(`${APIHOST}logout`,{
        token: getToken()
      }).then(function(res){
        destroySession();
        _this.props.history.push(`/login`);
      }).catch(function(res){
        destroySession();
        _this.props.history.push(`/login`);
      });
    }

    toggle = () =>{
      this.setState({ddOpen : !this.state.ddOpen})
    }

    render() {
      var back = this.props.past;
        return (
          <>
            <nav class="navbar navbar-light tool-bar-nl fixed-top">
              <button onClick={()=> this.props.history.push(back)} className="btn" ><i className="fa fa-arrow-left"></i></button>
              {this.props.hasMenu?
              <button onClick={() => this.handleLogout()} className="btn pull-right" ><i class="fas fa-power-off"></i></button>
              :null}
            </nav>
          </>
        );
      }
}

export default toolbarNL;