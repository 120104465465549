import React, { Component } from 'react';
import Axios from 'axios';
import {APIHOST,updateloginStatus,setMerchant,setToken,isLoggedIn,isOutletSet} from '../../../gaia';
import Notification from '../../Components/Notification'
import ToolBar from '../../Components/toolbarNL'
import Logo from '../../../assets/img/logo.png'

import { Button, Form, FormGroup, Label, Input, FormText,Spinner } from 'reactstrap';
 
class Login extends Component {

  constructor(props){
    super(props)
    if(isLoggedIn()){
      if(isOutletSet()){
        this.props.history.push(`/app`)
      }else{
        this.props.history.push(`/outlet`)
      } 
    }

    this.state = {
      username: '',
      password: '',
      sb: {msg : '', open : false, icon:''},
      loading: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange (event) {
    this.setState( {[event.target.name]: event.target.value} )
  }

  handleSubmit = (evt) =>{
    var that = this;
    evt.preventDefault();
    if(this.state.username === "" || this.state.password === ""){
      this.setState({sb : {msg : "Please enter username and password", open : true, icon : 'close'}})
      return;
    }
    this.setState( {loading : true});
    Axios.post(`${APIHOST}login`, {
      email: this.state.username,
      password: this.state.password,
      type: 1
    }).then(function(res){
        let {data} = res;
        updateloginStatus(true);
        setToken(data.access_token);
        setMerchant(data.details);
        that.setState( {loading : false});
        that.setState({sb : {msg : "Welcome Back", open : true, icon : 'check_circle'}})
        setTimeout(()=>{
          if(isOutletSet()){
            that.props.history.push(`/app/loyalty`)
          }else{
            that.props.history.push(`/outlet`)
          } 
        },3000)
        
    }).catch(function(er){
      that.setState( {loading : false}) 
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          that.setState({sb : {msg : er.response.data.error, open : true, icon : 'warning'}})
        }else{
          that.setState({sb : {msg : "A network error occured, please try again later.", open : true, icon : 'warning'}})
        }
      }else{
        that.setState({sb : {msg : er.message, open : true, icon : 'warning'}})
      }
      
    })
  }

  componentWillMount(){
    
  }

  clearNotification(){
    this.setState({sb : {open : false, msg : "", icon : ""}})
  }

  render() {
    const {loading,sb} = this.state;
    return (
      <>
      <div className="container bg">
      <ToolBar title="login" past="/" hasMenu={false} history={this.props.history} />
        <div id="login">
        <Notification open={sb.open} timeout={3000} clearNotification={()=>{this.clearNotification()}}  msg={sb.msg} icon={sb.icon} />
        <div className="inner">
          <div className="top">
            <div className="frm-box">
            <img src={Logo} className="central-logo" />
              <Form autoComplete="off" onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label for="username">USERNAME</Label>
                  <Input className="text-field" type="email" name="email" id="username" name="username" onChange={event => this.handleChange(event)} required />
                </FormGroup>
                <FormGroup  className="mb-4">
                  <Label for="username">PASSWORD</Label>
                  <Input className="text-field" type="password" name="email" id="password" name="password" onChange={event => this.handleChange(event)} required />
                </FormGroup>
              {loading ? 
                <Spinner color="primary" size="lg"/> :  
                <Button type="submit" className="tb-big-btn btn-secondary">LOG IN</Button>
              }
              </Form>
            </div>
          </div>
          <div className="btm">
           
          </div>
        </div>
      </div>
    </div>
    </>
    );
  }
}

export default Login;
