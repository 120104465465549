import React, {useEffect} from "react";
import axios from "axios";

import {destroySession} from './gaia';

const checkRequests= Wrapped => {
    function CheckRequests(props) {
        useEffect(()=>{
            axios.interceptors.response.use(function (response) {
                // Do something with response data
                return response;
            }, function (error) {
                if(typeof(error.response) !== "undefined"){
                    switch (error.response.status) {
                        case 503 :
                            props.history.push('/503') //we will redirect user into 503 page 
                            break
                        case 401 :
                            destroySession();
                            window.location.replace("/login")
                            //props.history.push('/login') //we will redirect user into 503 page 
                            break
                        default :
                            break
                    }
                }
                // Do something with response error
                return Promise.reject(error);
            });
        })

        return (
            <Wrapped {...props} />
        )
    }
    return CheckRequests
}

export default checkRequests