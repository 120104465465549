import React, { Component } from 'react';
import {isLoggedIn,isOutletSet} from '../../../gaia';
import {Button} from '@rmwc/button';

import Logo from '../../../assets/img/logo.png'

class Login extends Component {

  constructor(props){
    super(props)
    if(isLoggedIn()){
      if(isOutletSet()){
        this.props.history.push(`/app`)
      }
    }

    this.state = {}
  }

  componentWillMount(){
    
  }

  render() {
    const {loading,sb} = this.state;
    return (
      <div id="landing">
        <div className="inner">
          <div className="top">
            <img className="landing-logo" src={Logo} />
          </div>
          <div className="btm">
            <Button onClick={()=> this.props.history.push("/login")} className="tb-big-btn btn-blue" label="LOG IN" raised />
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
