import React, { Component } from 'react';
import {APIHOST,getToken,destroySession,getMerchantDetail, permaDeath} from '../../gaia';
import Axios from 'axios';

import Drawer from './sideDrawer'
import {Navbar,NavbarToggler,NavbarBrand} from 'reactstrap';

import Logo from '../../assets/img/trueblu-full.png'
import SideDrawer from './sideDrawer';

class toolbar extends Component {

    constructor(props){
      super(props)
      this.state = {
        sideOpen: false,
      }
      this.handleLogout = this.handleLogout.bind(this);
      this.toggle = this.toggle.bind(this);
    }

    handleLogout(){
      var _this = this
      Axios.post(`${APIHOST}logout`,{
        token: getToken()
      }).then(function(res){
        permaDeath();
        destroySession();
        _this.props.history.push(`/login`);
      }).catch(function(res){
        permaDeath();
        destroySession();
        _this.props.history.push(`/login`);
      });
    }

    toggle(){
      this.setState({ sideOpen : !this.state.sideOpen })
    }

    render() {
      var back = this.props.past;
        return (
          <>
            <Navbar color="light" light fixed="top">
              <NavbarBrand href="/"><img className="top-bar-logo" src={Logo} /></NavbarBrand>
              <NavbarToggler onClick={this.toggle} />
              <Drawer open={this.state.sideOpen} history={this.props.history} logOut={this.handleLogout} toggle={this.toggle} />
            </Navbar>
          </>
        );
      }
}

export default toolbar;