import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ToolBar from '../../Components/toolbar'
import Axios from 'axios';
import routes from '../../../routes';
import IdleTimer from 'react-idle-timer';
import IdleModal from '../../Components/IdleModal';
import {isLoggedIn,isOutletSet,getToken,APIHOST,destroySession} from '../../../gaia';
import { CircularProgress } from '@rmwc/circular-progress';
import poweredby from '../../../assets/img/poweredby.png'






class Dashboard extends Component {

  constructor(props){
    super(props)

    if(!isLoggedIn()){
        this.props.history.push(`/login`)
    }else{
      if(!isOutletSet()){
        this.props.history.push(`/outlet`)
      }
    }

    this.state = {
      timeout:1000 * 60 * 15,//15 mins
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      loading: false
    }
    this.checkLogin = this.checkLogin.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }

  _onAction(e) {
    console.log("action")
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    console.log("active")
    this.setState({isTimedOut: false})
  }
 
  _onIdle(e) {
    const isTimedOut = this.state.isTimedOut

    if (isTimedOut) {
     
      //this.handleLogout();
    } else {
      //console.log("user not active")
      //this.handleLogout();
      //this.setState({showModal: true})
      //this.idleTimer.reset();
      //this.setState({isTimedOut: true})
    }
  }

  handleChange (event) {
    this.setState( {[event.target.name]: event.target.value} )
  }

  checkLogin(){
    if(!isLoggedIn()){
      this.props.history.push(`/login`);
    }
  }

  componentWillMount(){
  
  }

  handleClose() {
    this.setState({showModal: false})
  }

  handleLogout = () =>{
    var _this = this
    Axios.post(`${APIHOST}logout`,{
      token: getToken()
    }).then(function(res){
      destroySession();
      _this.props.history.push(`/login`);
    }).catch(function(res){
      destroySession();
      _this.props.history.push(`/login`);
    });
  }

  render() { 
    //const {loading} = this.state;  
    var _this = this;
    return (
      <div className="container">
        <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout} />
        <IdleModal open={this.state.showModal} handleClose={()=>this.handleClose()} handleLogout={()=>this.handleLogout()} />
        <ToolBar title="dashboard" past="/" hasMenu={true} history={this.props.history} />
        <Switch>
          {routes.map((route, idx) => {
            return route.component ? (<Route key={idx} path={route.path} history={_this.props.history}  exact={route.exact} name={route.name} render={props => (
              <route.component {...props} />
            )} />)
            : (null);
            },   
          )} 
          <Redirect from="/" to="/app" />
        </Switch>
        <div className="footer"><img src={poweredby} /></div>
      </div>
      
    );
  }
}

export default Dashboard;
