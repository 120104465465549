import React, { Component } from 'react';
import Notification from '../Components/Notification'
import {Dialog,DialogTitle,DialogContent,DialogActions, DialogButton} from '@rmwc/dialog';
import {TextField} from '@rmwc/textfield';
import { CircularProgress } from '@rmwc/circular-progress';

import {APIHOST, getToken, getMerchantDetail,getCustomer,getCustomerHp} from '../../gaia';
import Axios from 'axios';

import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';

class IdleModal extends Component {

    constructor(props){
      super(props)
      this.state = {
        loading :false,
        pin : "",
        sb: {msg : '', open : false, icon:''}
      }

    }

    render() {

      const {loading,sb} = this.state;

        return (
          <>
            <Notification open={sb.open} timeout={3000} clearNotification={()=>{this.clearNotification()}}  msg={sb.msg} icon={sb.icon} />
            <Dialog open={this.props.open} className="login-modal">
              <DialogTitle>User Notification</DialogTitle>
              <DialogContent>
                <p>The system has detected user inactivity. You will be logged out if you dont respond.</p>
              </DialogContent>
              <DialogActions>
              {loading ? 
                <CircularProgress size="large" /> : 
                <>
                  <DialogButton className="btn-blue" onClick={() => this.props.handleClose()} >Close</DialogButton>
                  <DialogButton className="btn-blue" onClick={() => this.props.handleLogout()}>Logout</DialogButton>
                </>
              }
              </DialogActions>
            </Dialog>
          </>
        );
      }
}

export default IdleModal;