import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import CheckRequests from './interceptor'


//v3.2
import 'bootstrap/dist/css/bootstrap.min.css';
// Import Font Awesome Icons Set
import '@fortawesome/fontawesome-free/css/all.css';


//necessary evils
import 'material-components-web/dist/material-components-web.min.css';
import '@rmwc/circular-progress/circular-progress.css';
import '@material/button/dist/mdc.button.css';
import '@material/toolbar/dist/mdc.toolbar.css';
import '@material/dialog/dist/mdc.dialog.css';

// Import Main styles for this application
import './assets/css/tb-fonts.css'
import './assets/css/style.css'

//import Main scaffolding
import {Login,Landing,Outlet,Dashboard} from './views/Pages';
 
class App extends Component {
  render() {
    return (
      <BrowserRouter>   
        <Switch>
          <Route exact path="/app" name="Dashboard" component={Dashboard} />
          <Route exact path="/app/menu" name="Dashboard" component={Dashboard} />
          <Route exact path="/app/customer-register" name="Dashboard" component={Dashboard} />
          <Route exact path="/app/customer-login" name="Dashboard" component={Dashboard} />
          <Route exact path="/app/loyalty" name="Loyalty" component={Dashboard} />
          <Route exact path="/app/store" name="Store" component={Dashboard} />
          <Route exact path="/app/topups" name="topups" component={Dashboard} />
          <Route exact path="/app/fasttrack" name="fasttrack" component={Dashboard} />
          <Route exact path="/app/transactions" name="Transactions" component={Dashboard} />
          <Route exact path="/app/redemptions" name="Qr Redemptions" component={Dashboard} />
          <Route exact path="/app/queue" name="Queue" component={Dashboard} />

          {/*deprecated*/}
          <Route exact path="/app/instagram" name="Instagram Feed" component={Dashboard} />
          <Route exact path="/app/claimrewards" name="Claim Coupons" component={Dashboard} />
          <Route exact path="/app/summary" name="Summary" component={Dashboard} />
          <Route exact path="/app/fastrewards" name="Fast Rewards" component={Dashboard} />
          <Route exact path="/app/qr" name="Qr Page" component={Dashboard} />
          
          
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/outlet" name="Merchant Outlets" component={Outlet} />
          <Route exact path="/" name="Landing Page" component={Landing} />
        </Switch> 
      </BrowserRouter>
    );  
  }
}
export default CheckRequests(App);
