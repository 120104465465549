import React, { Component } from 'react';
import {getMerchantDetail, getOutletDetail,APIHOST,getToken,destroySession} from '../../gaia'
import { createBrowserHistory } from "history";

import Axios from 'axios';

import {Collapse,Nav,NavItem,NavLink,UncontrolledDropdown,DropdownToggle,DropdownMenu,DropdownItem,NavbarText} from 'reactstrap';


class sideDrawer extends Component {

    constructor(props){
      super(props)

      this.state = {
        open: false
      }
    }


    goTo = (route) =>{
      this.props.history.push(route);
      this.props.toggle()
    }

    goToPortal = (route) =>{
      window.open('https://ma.trueblu.co/', '_blank');
    }

    componentDidMount(){
      this.setState({ open : this.props.open})
      
    }
    

    render() {

      const history = createBrowserHistory().location.pathname;
        return (
            <Collapse isOpen={this.props.open} navbar>
              <NavbarText><strong>{getMerchantDetail()}</strong></NavbarText>
              <NavbarText>{getOutletDetail()}</NavbarText>
              <hr />
              <Nav className="mr-auto" navbar>
                <NavItem>
                  <NavLink onClick={() => this.goTo("/app")} className={history == "/app" ? "active" : ""} >Dashboard</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={() => this.goTo("/app/customer-register")} className={history == "/app/customer-register" ? "active" : ""} >Register New Customer</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink  onClick={() => this.goTo("/app/menu")}  className={history == "/app/menu" ? "active" : ""}>Login Customer</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink  onClick={() => this.goTo("/app/topups")}  className={history == "/app/topups" ? "active" : ""}>Topup Customer</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={() => this.goTo("/app/transactions")}  className={history == "/app/transactions" ? "active" : ""}>Transactions</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={() => this.goTo("/app/redemptions")}  className={history == "/app/redemptions" ? "active" : ""}>QR Redemptions</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={() => this.goToPortal()}>Merchant Portal</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="logout" onClick={() => this.props.logOut()}>Logout</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
      
        );
      }
}

export default sideDrawer;